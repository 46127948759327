<template>
  <div class="calendar">
    <div class="flex flex-justify-between margin-b-10">
        <div>{{ currentYear }}年{{ currentMonth }}月</div>
        <div>
            <button class="c-button" @click="prevMonth">上月</button>
            <button class="c-button margin-l-10" @click="nextMonth">下月</button>
            <button class="c-button margin-l-20" @click="print">打印</button>
            <button class="c-button margin-l-20" @click="toImage">下载图片</button>
        </div>
    </div>
    <table ref="table">
      <thead>
        <tr><th :colspan="weekDays.length" style="font-size: 22px; font-weight: 800;">{{ currentYear }}年{{ currentMonth }}月</th></tr>
        <tr>
          <th v-for="(day, index) in weekDays" :key="'th-'+index">星期{{ day }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(week, index) in weeks">
            <tr :key="'wd-'+index">
                <td v-for="(day, index) in week" :key="'day-'+index" style="text-align: center;">{{ day ? moment(day).format('MM月DD日'): '' }}</td>
            </tr>
            <tr :key="'wc'-index">
                <td v-for="(day, index) in week" :key="'content-'+index" style="height:80px" v-html="day ? content : ''"></td>
            </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment';
import domtoimage from 'dom-to-image';
export default {
  data() {
    return {
        moment,
      weekDays: ['一', '二', '三', '四', '五', '六', '日'],
      currentYear: 2023,
      currentMonth: 4,
      weeks: [],
      content: '',
    }
  },
  created() {
    this.content = `<p><span class="check">□</span>①背（识字）</p>
                    <p><span class="check">□</span>②写.背（生字手册）</p>
                    <p><span class="check">□</span>③写.背（生字摹写本）</p>
                    <p><span class="check">□</span>④做题（语文、数学练习册）*</p>
                    <p><span class="check">□</span>⑤奥数  <span class="check margin-l-15">□</span>⑥背英语</p>
                    <p><span class="check">□</span>⑦背语文*<span class="check margin-l-15">□</span>⑧写英语</p>
                    <p><span class="check">□</span>⑨看绘本<span class="check margin-l-15">□</span>⑩预习复习*</p>`;
    this.currentYear = new Date().getFullYear();
    this.currentMonth = new Date().getMonth() + 1;
    this.generateCalendar()
  },
  methods: {
    generateCalendar() {
      const firstDay = new Date(this.currentYear, this.currentMonth - 1, 1)
      const lastDay = new Date(this.currentYear, this.currentMonth, 0)
      const firstDayWeekDay = firstDay.getDay()
      const lastDayWeekDay = lastDay.getDay()
      const weeks = [[]]
      let currentWeek = 0

      for (let i = 0; i < firstDayWeekDay - 1; i++) {
        weeks[currentWeek].push('')
      }

      for (let i = 1; i <= lastDay.getDate(); i++) {
        if (weeks[currentWeek].length === 7) {
          currentWeek++
          weeks[currentWeek] = []
        }
        // weeks[currentWeek].push(i)
        weeks[currentWeek].push(moment(`${this.currentYear}-${this.currentMonth}-${i}`));
      }

    if(lastDayWeekDay - 1 > -1){
        for (let i = lastDayWeekDay - 1; i < 6; i++) {
            weeks[currentWeek].push('')
        }
      }

      this.weeks = weeks
    },
    prevMonth(){
        let month = this.currentMonth - 1;
        let year = this.currentYear;
        if(month <= 0){
            month = 12;
            year = year - 1;
        }
        this.currentMonth = month;
        this.currentYear = year; 
        this.generateCalendar();
    },
    nextMonth(){
        let month = this.currentMonth + 1;
        let year = this.currentYear;
        if(month > 12){
            month = 1;
            year = year + 1;
        }
        this.currentMonth = month;
        this.currentYear = year; 
        this.generateCalendar();
    },
    print(){
        let style = `<style>*{margin:0;padding:0;}table{width:100%;border-collapse:collapse;font-family:'幼圆';}th,td{width:14.28%;padding:5px;border:1px solid #ccc;}th{background-color:#f8f8f8;font-weight:bold;}p{line-height:1.3;font-size:12px;}.check{margin-right:4px;}.margin-l-15{margin-left:15px;}</style>`
        const printArea = this.$refs.table;
        const printContent = printArea.outerHTML;
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`<html><head><title>打印页面</title>${style}</head><body class="calendar">${printContent}</body></html>`);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    },
    toImage(){
        let self = this;
        domtoimage.toPng(this.$refs.table)
            .then(function (url) {
                console.log(url);
                window.open(url, "_blank")
                // window.saveAs(blob, `calendar-${self.currentYear}-${self.currentMonth}.png`);
            });
    },
  }
}
</script>
<style lang="scss">
.calendar{
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 1px 1px 2px #ccc, -1px -1px 2px #ccc;
    table {
        width: 100%;
        border-collapse: collapse;
        font-family: '幼圆';
    }
    th,
    td {
        width: 14.28%;
        padding: 5px;
        border: 1px solid #ccc;
    }
    th{
        background-color: #f8f8f8;
        font-weight: bold;
    }
    p{
        line-height: 1.3;
        font-size: 12px;
    }
    .check{
        margin-right: 4px;
    }
    .margin-l-15{
        margin-left:15px;
    }
}

</style>
